export default {
  'Deutsch': {
    'DIN A3': [
      {
        'preview': new URL('../assets/posters/de_297x420mm_show_you_care_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/de_297x420mm_show_you_care_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/de_297x420mm_show_you_care_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/de_297x420mm_show_you_care_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/de_297x420mm_show_you_care_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/de_297x420mm_show_you_care_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/de_297x420mm_show_you_care_c.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/de_297x420mm_show_you_care_c.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/de_297x420mm_show_you_care_c.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/de_297x420mm_do_the_right_things_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/de_297x420mm_do_the_right_things_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/de_297x420mm_do_the_right_things_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/de_297x420mm_do_the_right_things_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/de_297x420mm_do_the_right_things_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/de_297x420mm_do_the_right_things_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/de_297x420mm_do_the_right_things_c.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/de_297x420mm_do_the_right_things_c.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/de_297x420mm_do_the_right_things_c.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/de_297x420mm_win_as_a_team_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/de_297x420mm_win_as_a_team_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/de_297x420mm_win_as_a_team_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/de_297x420mm_win_as_a_team_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/de_297x420mm_win_as_a_team_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/de_297x420mm_win_as_a_team_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/de_297x420mm_win_as_a_team_c.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/de_297x420mm_win_as_a_team_c.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/de_297x420mm_win_as_a_team_c.pdf', import.meta.url),
      },
    ],
    'US Tabloid': null,
    '700x1000mm': [
      {
        'preview': new URL('../assets/posters/de_700x1000mm_show_you_care_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/de_700x1000mm_show_you_care_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/de_700x1000mm_show_you_care_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/de_700x1000mm_show_you_care_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/de_700x1000mm_show_you_care_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/de_700x1000mm_show_you_care_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/de_700x1000mm_show_you_care_c.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/de_700x1000mm_show_you_care_c.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/de_700x1000mm_show_you_care_c.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/de_700x1000mm_do_the_right_things_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/de_700x1000mm_do_the_right_things_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/de_700x1000mm_do_the_right_things_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/de_700x1000mm_do_the_right_things_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/de_700x1000mm_do_the_right_things_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/de_700x1000mm_do_the_right_things_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/de_700x1000mm_do_the_right_things_c.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/de_700x1000mm_do_the_right_things_c.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/de_700x1000mm_do_the_right_things_c.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/de_700x1000mm_win_as_a_team_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/de_700x1000mm_win_as_a_team_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/de_700x1000mm_win_as_a_team_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/de_700x1000mm_win_as_a_team_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/de_700x1000mm_win_as_a_team_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/de_700x1000mm_win_as_a_team_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/de_700x1000mm_win_as_a_team_c.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/de_700x1000mm_win_as_a_team_c.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/de_700x1000mm_win_as_a_team_c.pdf', import.meta.url),
      },
    ],
  },
  'English': {
    'DIN A3': [
      {
        'preview': new URL('../assets/posters/en_297x420mm_show_you_care_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/en_297x420mm_show_you_care_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/en_297x420mm_show_you_care_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/en_297x420mm_show_you_care_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/en_297x420mm_show_you_care_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/en_297x420mm_show_you_care_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/en_297x420mm_show_you_care_c.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/en_297x420mm_show_you_care_c.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/en_297x420mm_show_you_care_c.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/en_297x420mm_do_the_right_things_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/en_297x420mm_do_the_right_things_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/en_297x420mm_do_the_right_things_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/en_297x420mm_do_the_right_things_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/en_297x420mm_do_the_right_things_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/en_297x420mm_do_the_right_things_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/en_297x420mm_do_the_right_things_c.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/en_297x420mm_do_the_right_things_c.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/en_297x420mm_do_the_right_things_c.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/en_297x420mm_win_as_a_team_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/en_297x420mm_win_as_a_team_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/en_297x420mm_win_as_a_team_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/en_297x420mm_win_as_a_team_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/en_297x420mm_win_as_a_team_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/en_297x420mm_win_as_a_team_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/en_297x420mm_win_as_a_team_c.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/en_297x420mm_win_as_a_team_c.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/en_297x420mm_win_as_a_team_c.pdf', import.meta.url),
      },
    ],
    'US Tabloid': [
      {
        'preview': new URL('../assets/posters/en_11x17inch_show_you_care_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/en_11x17inch_show_you_care_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/en_11x17inch_show_you_care_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/en_11x17inch_show_you_care_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/en_11x17inch_show_you_care_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/en_11x17inch_show_you_care_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/en_11x17inch_show_you_care_c.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/en_11x17inch_show_you_care_c.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/en_11x17inch_show_you_care_c.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/en_11x17inch_do_the_right_things_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/en_11x17inch_do_the_right_things_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/en_11x17inch_do_the_right_things_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/en_11x17inch_do_the_right_things_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/en_11x17inch_do_the_right_things_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/en_11x17inch_do_the_right_things_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/en_11x17inch_do_the_right_things_c.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/en_11x17inch_do_the_right_things_c.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/en_11x17inch_do_the_right_things_c.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/en_11x17inch_win_as_a_team_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/en_11x17inch_win_as_a_team_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/en_11x17inch_win_as_a_team_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/en_11x17inch_win_as_a_team_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/en_11x17inch_win_as_a_team_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/en_11x17inch_win_as_a_team_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/en_11x17inch_win_as_a_team_c.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/en_11x17inch_win_as_a_team_c.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/en_11x17inch_win_as_a_team_c.pdf', import.meta.url),
      },
    ],
    '700x1000mm': [
      {
        'preview': new URL('../assets/posters/en_700x1000mm_show_you_care_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/en_700x1000mm_show_you_care_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/en_700x1000mm_show_you_care_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/en_700x1000mm_show_you_care_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/en_700x1000mm_show_you_care_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/en_700x1000mm_show_you_care_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/en_700x1000mm_show_you_care_c.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/en_700x1000mm_show_you_care_c.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/en_700x1000mm_show_you_care_c.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/en_700x1000mm_do_the_right_things_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/en_700x1000mm_do_the_right_things_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/en_700x1000mm_do_the_right_things_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/en_700x1000mm_do_the_right_things_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/en_700x1000mm_do_the_right_things_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/en_700x1000mm_do_the_right_things_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/en_700x1000mm_do_the_right_things_c.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/en_700x1000mm_do_the_right_things_c.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/en_700x1000mm_do_the_right_things_c.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/en_700x1000mm_win_as_a_team_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/en_700x1000mm_win_as_a_team_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/en_700x1000mm_win_as_a_team_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/en_700x1000mm_win_as_a_team_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/en_700x1000mm_win_as_a_team_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/en_700x1000mm_win_as_a_team_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/en_700x1000mm_win_as_a_team_c.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/en_700x1000mm_win_as_a_team_c.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/en_700x1000mm_win_as_a_team_c.pdf', import.meta.url),
      },
    ],
  },
  'Español (Latam)': {
    'DIN A3': [
      {
        'preview': new URL('../assets/posters/es_297x420mm_show_you_care_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/es_297x420mm_show_you_care_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/es_297x420mm_show_you_care_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/es_297x420mm_show_you_care_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/es_297x420mm_show_you_care_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/es_297x420mm_show_you_care_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/es_297x420mm_show_you_care_c.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/es_297x420mm_show_you_care_c.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/es_297x420mm_show_you_care_c.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/es_297x420mm_do_the_right_things_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/es_297x420mm_do_the_right_things_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/es_297x420mm_do_the_right_things_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/es_297x420mm_do_the_right_things_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/es_297x420mm_do_the_right_things_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/es_297x420mm_do_the_right_things_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/es_297x420mm_do_the_right_things_c.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/es_297x420mm_do_the_right_things_c.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/es_297x420mm_do_the_right_things_c.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/es_297x420mm_win_as_a_team_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/es_297x420mm_win_as_a_team_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/es_297x420mm_win_as_a_team_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/es_297x420mm_win_as_a_team_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/es_297x420mm_win_as_a_team_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/es_297x420mm_win_as_a_team_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/es_297x420mm_win_as_a_team_c.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/es_297x420mm_win_as_a_team_c.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/es_297x420mm_win_as_a_team_c.pdf', import.meta.url),
      },
    ],
    'US Tabloid': null,
    '700x1000mm': [
      {
        'preview': new URL('../assets/posters/es_700x1000mm_show_you_care_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/es_700x1000mm_show_you_care_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/es_700x1000mm_show_you_care_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/es_700x1000mm_show_you_care_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/es_700x1000mm_show_you_care_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/es_700x1000mm_show_you_care_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/es_700x1000mm_show_you_care_c.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/es_700x1000mm_show_you_care_c.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/es_700x1000mm_show_you_care_c.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/es_700x1000mm_do_the_right_things_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/es_700x1000mm_do_the_right_things_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/es_700x1000mm_do_the_right_things_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/es_700x1000mm_do_the_right_things_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/es_700x1000mm_do_the_right_things_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/es_700x1000mm_do_the_right_things_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/es_700x1000mm_do_the_right_things_c.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/es_700x1000mm_do_the_right_things_c.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/es_700x1000mm_do_the_right_things_c.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/es_700x1000mm_win_as_a_team_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/es_700x1000mm_win_as_a_team_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/es_700x1000mm_win_as_a_team_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/es_700x1000mm_win_as_a_team_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/es_700x1000mm_win_as_a_team_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/es_700x1000mm_win_as_a_team_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/es_700x1000mm_win_as_a_team_c.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/es_700x1000mm_win_as_a_team_c.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/es_700x1000mm_win_as_a_team_c.pdf', import.meta.url),
      },
    ],
  },
  'Français': {
    'DIN A3': [
      {
        'preview': new URL('../assets/posters/fr_297x420mm_show_you_care_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/fr_297x420mm_show_you_care_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/fr_297x420mm_show_you_care_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/fr_297x420mm_show_you_care_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/fr_297x420mm_show_you_care_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/fr_297x420mm_show_you_care_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/fr_297x420mm_show_you_care_c.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/fr_297x420mm_show_you_care_c.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/fr_297x420mm_show_you_care_c.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/fr_297x420mm_do_the_right_things_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/fr_297x420mm_do_the_right_things_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/fr_297x420mm_do_the_right_things_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/fr_297x420mm_do_the_right_things_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/fr_297x420mm_do_the_right_things_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/fr_297x420mm_do_the_right_things_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/fr_297x420mm_do_the_right_things_c.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/fr_297x420mm_do_the_right_things_c.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/fr_297x420mm_do_the_right_things_c.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/fr_297x420mm_win_as_a_team_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/fr_297x420mm_win_as_a_team_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/fr_297x420mm_win_as_a_team_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/fr_297x420mm_win_as_a_team_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/fr_297x420mm_win_as_a_team_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/fr_297x420mm_win_as_a_team_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/fr_297x420mm_win_as_a_team_c.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/fr_297x420mm_win_as_a_team_c.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/fr_297x420mm_win_as_a_team_c.pdf', import.meta.url),
      },
    ],
    'US Tabloid': null,
    '700x1000mm': [
      {
        'preview': new URL('../assets/posters/fr_700x1000mm_show_you_care_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/fr_700x1000mm_show_you_care_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/fr_700x1000mm_show_you_care_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/fr_700x1000mm_show_you_care_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/fr_700x1000mm_show_you_care_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/fr_700x1000mm_show_you_care_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/fr_700x1000mm_show_you_care_c.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/fr_700x1000mm_show_you_care_c.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/fr_700x1000mm_show_you_care_c.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/fr_700x1000mm_do_the_right_things_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/fr_700x1000mm_do_the_right_things_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/fr_700x1000mm_do_the_right_things_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/fr_700x1000mm_do_the_right_things_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/fr_700x1000mm_do_the_right_things_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/fr_700x1000mm_do_the_right_things_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/fr_700x1000mm_do_the_right_things_c.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/fr_700x1000mm_do_the_right_things_c.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/fr_700x1000mm_do_the_right_things_c.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/fr_700x1000mm_win_as_a_team_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/fr_700x1000mm_win_as_a_team_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/fr_700x1000mm_win_as_a_team_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/fr_700x1000mm_win_as_a_team_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/fr_700x1000mm_win_as_a_team_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/fr_700x1000mm_win_as_a_team_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/fr_700x1000mm_win_as_a_team_c.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/fr_700x1000mm_win_as_a_team_c.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/fr_700x1000mm_win_as_a_team_c.pdf', import.meta.url),
      },
    ],
  },
  'Italiano': {
    'DIN A3': [
      {
        'preview': new URL('../assets/posters/it_297x420mm_show_you_care_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/it_297x420mm_show_you_care_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/it_297x420mm_show_you_care_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/it_297x420mm_show_you_care_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/it_297x420mm_show_you_care_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/it_297x420mm_show_you_care_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/it_297x420mm_show_you_care_c.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/it_297x420mm_show_you_care_c.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/it_297x420mm_show_you_care_c.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/it_297x420mm_do_the_right_things_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/it_297x420mm_do_the_right_things_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/it_297x420mm_do_the_right_things_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/it_297x420mm_do_the_right_things_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/it_297x420mm_do_the_right_things_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/it_297x420mm_do_the_right_things_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/it_297x420mm_do_the_right_things_c.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/it_297x420mm_do_the_right_things_c.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/it_297x420mm_do_the_right_things_c.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/it_297x420mm_win_as_a_team_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/it_297x420mm_win_as_a_team_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/it_297x420mm_win_as_a_team_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/it_297x420mm_win_as_a_team_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/it_297x420mm_win_as_a_team_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/it_297x420mm_win_as_a_team_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/it_297x420mm_win_as_a_team_c.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/it_297x420mm_win_as_a_team_c.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/it_297x420mm_win_as_a_team_c.pdf', import.meta.url),
      },
    ],
    'US Tabloid': null,
    '700x1000mm': [
      {
        'preview': new URL('../assets/posters/it_700x1000mm_show_you_care_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/it_700x1000mm_show_you_care_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/it_700x1000mm_show_you_care_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/it_700x1000mm_show_you_care_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/it_700x1000mm_show_you_care_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/it_700x1000mm_show_you_care_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/it_700x1000mm_show_you_care_c.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/it_700x1000mm_show_you_care_c.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/it_700x1000mm_show_you_care_c.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/it_700x1000mm_do_the_right_things_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/it_700x1000mm_do_the_right_things_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/it_700x1000mm_do_the_right_things_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/it_700x1000mm_do_the_right_things_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/it_700x1000mm_do_the_right_things_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/it_700x1000mm_do_the_right_things_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/it_700x1000mm_do_the_right_things_c.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/it_700x1000mm_do_the_right_things_c.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/it_700x1000mm_do_the_right_things_c.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/it_700x1000mm_win_as_a_team_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/it_700x1000mm_win_as_a_team_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/it_700x1000mm_win_as_a_team_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/it_700x1000mm_win_as_a_team_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/it_700x1000mm_win_as_a_team_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/it_700x1000mm_win_as_a_team_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/it_700x1000mm_win_as_a_team_c.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/it_700x1000mm_win_as_a_team_c.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/it_700x1000mm_win_as_a_team_c.pdf', import.meta.url),
      },
    ],
  },
  'Português (Brasil)': {
    'DIN A3': [
      {
        'preview': new URL('../assets/posters/pt_297x420mm_show_you_care_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/pt_297x420mm_show_you_care_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/pt_297x420mm_show_you_care_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/pt_297x420mm_show_you_care_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/pt_297x420mm_show_you_care_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/pt_297x420mm_show_you_care_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/pt_297x420mm_show_you_care_c.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/pt_297x420mm_show_you_care_c.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/pt_297x420mm_show_you_care_c.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/pt_297x420mm_do_the_right_things_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/pt_297x420mm_do_the_right_things_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/pt_297x420mm_do_the_right_things_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/pt_297x420mm_do_the_right_things_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/pt_297x420mm_do_the_right_things_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/pt_297x420mm_do_the_right_things_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/pt_297x420mm_do_the_right_things_c.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/pt_297x420mm_do_the_right_things_c.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/pt_297x420mm_do_the_right_things_c.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/pt_297x420mm_win_as_a_team_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/pt_297x420mm_win_as_a_team_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/pt_297x420mm_win_as_a_team_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/pt_297x420mm_win_as_a_team_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/pt_297x420mm_win_as_a_team_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/pt_297x420mm_win_as_a_team_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/pt_297x420mm_win_as_a_team_c.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/pt_297x420mm_win_as_a_team_c.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/pt_297x420mm_win_as_a_team_c.pdf', import.meta.url),
      },
    ],
    'US Tabloid': null,
    '700x1000mm': [
      {
        'preview': new URL('../assets/posters/pt_700x1000mm_show_you_care_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/pt_700x1000mm_show_you_care_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/pt_700x1000mm_show_you_care_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/pt_700x1000mm_show_you_care_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/pt_700x1000mm_show_you_care_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/pt_700x1000mm_show_you_care_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/pt_700x1000mm_show_you_care_c.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/pt_700x1000mm_show_you_care_c.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/pt_700x1000mm_show_you_care_c.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/pt_700x1000mm_do_the_right_things_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/pt_700x1000mm_do_the_right_things_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/pt_700x1000mm_do_the_right_things_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/pt_700x1000mm_do_the_right_things_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/pt_700x1000mm_do_the_right_things_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/pt_700x1000mm_do_the_right_things_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/pt_700x1000mm_do_the_right_things_c.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/pt_700x1000mm_do_the_right_things_c.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/pt_700x1000mm_do_the_right_things_c.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/pt_700x1000mm_win_as_a_team_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/pt_700x1000mm_win_as_a_team_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/pt_700x1000mm_win_as_a_team_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/pt_700x1000mm_win_as_a_team_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/pt_700x1000mm_win_as_a_team_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/pt_700x1000mm_win_as_a_team_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/pt_700x1000mm_win_as_a_team_c.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/pt_700x1000mm_win_as_a_team_c.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/pt_700x1000mm_win_as_a_team_c.pdf', import.meta.url),
      },
    ],
  },
  '日本語': {
    'DIN A3': [
      {
        'preview': new URL('../assets/posters/jp_297x420mm_show_you_care_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/jp_297x420mm_show_you_care_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/jp_297x420mm_show_you_care_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/jp_297x420mm_show_you_care_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/jp_297x420mm_show_you_care_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/jp_297x420mm_show_you_care_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/jp_297x420mm_show_you_care_c.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/jp_297x420mm_show_you_care_c.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/jp_297x420mm_show_you_care_c.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/jp_297x420mm_do_the_right_things_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/jp_297x420mm_do_the_right_things_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/jp_297x420mm_do_the_right_things_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/jp_297x420mm_do_the_right_things_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/jp_297x420mm_do_the_right_things_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/jp_297x420mm_do_the_right_things_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/jp_297x420mm_do_the_right_things_c.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/jp_297x420mm_do_the_right_things_c.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/jp_297x420mm_do_the_right_things_c.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/jp_297x420mm_win_as_a_team_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/jp_297x420mm_win_as_a_team_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/jp_297x420mm_win_as_a_team_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/jp_297x420mm_win_as_a_team_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/jp_297x420mm_win_as_a_team_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/jp_297x420mm_win_as_a_team_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/jp_297x420mm_win_as_a_team_c.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/jp_297x420mm_win_as_a_team_c.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/jp_297x420mm_win_as_a_team_c.pdf', import.meta.url),
      },
    ],
    'US Tabloid': null,
    '700x1000mm': [
      {
        'preview': new URL('../assets/posters/jp_700x1000mm_show_you_care_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/jp_700x1000mm_show_you_care_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/jp_700x1000mm_show_you_care_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/jp_700x1000mm_show_you_care_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/jp_700x1000mm_show_you_care_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/jp_700x1000mm_show_you_care_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/jp_700x1000mm_show_you_care_c.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/jp_700x1000mm_show_you_care_c.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/jp_700x1000mm_show_you_care_c.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/jp_700x1000mm_do_the_right_things_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/jp_700x1000mm_do_the_right_things_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/jp_700x1000mm_do_the_right_things_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/jp_700x1000mm_do_the_right_things_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/jp_700x1000mm_do_the_right_things_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/jp_700x1000mm_do_the_right_things_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/jp_700x1000mm_do_the_right_things_c.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/jp_700x1000mm_do_the_right_things_c.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/jp_700x1000mm_do_the_right_things_c.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/jp_700x1000mm_win_as_a_team_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/jp_700x1000mm_win_as_a_team_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/jp_700x1000mm_win_as_a_team_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/jp_700x1000mm_win_as_a_team_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/jp_700x1000mm_win_as_a_team_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/jp_700x1000mm_win_as_a_team_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/jp_700x1000mm_win_as_a_team_c.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/jp_700x1000mm_win_as_a_team_c.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/jp_700x1000mm_win_as_a_team_c.pdf', import.meta.url),
      },
    ],
  },
  '한국어': {
    'DIN A3': [
        {
          'preview': new URL('../assets/posters/ko_297x420mm_show_you_care_a.jpg', import.meta.url),
          'thumbnail': new URL('../assets/posters/ko_297x420mm_show_you_care_a.jpg?width=250', import.meta.url),
          'pdf': new URL('../assets/posters/ko_297x420mm_show_you_care_a.pdf', import.meta.url),
        },
        {
          'preview': new URL('../assets/posters/ko_297x420mm_show_you_care_b.jpg', import.meta.url),
          'thumbnail': new URL('../assets/posters/ko_297x420mm_show_you_care_b.jpg?width=250', import.meta.url),
          'pdf': new URL('../assets/posters/ko_297x420mm_show_you_care_b.pdf', import.meta.url),
        },
        {
          'preview': new URL('../assets/posters/ko_297x420mm_show_you_care_c.jpg', import.meta.url),
          'thumbnail': new URL('../assets/posters/ko_297x420mm_show_you_care_c.jpg?width=250', import.meta.url),
          'pdf': new URL('../assets/posters/ko_297x420mm_show_you_care_c.pdf', import.meta.url),
        },
        {
          'preview': new URL('../assets/posters/ko_297x420mm_do_the_right_things_a.jpg', import.meta.url),
          'thumbnail': new URL('../assets/posters/ko_297x420mm_do_the_right_things_a.jpg?width=250', import.meta.url),
          'pdf': new URL('../assets/posters/ko_297x420mm_do_the_right_things_a.pdf', import.meta.url),
        },
        {
          'preview': new URL('../assets/posters/ko_297x420mm_do_the_right_things_b.jpg', import.meta.url),
          'thumbnail': new URL('../assets/posters/ko_297x420mm_do_the_right_things_b.jpg?width=250', import.meta.url),
          'pdf': new URL('../assets/posters/ko_297x420mm_do_the_right_things_b.pdf', import.meta.url),
        },
        {
          'preview': new URL('../assets/posters/ko_297x420mm_do_the_right_things_c.jpg', import.meta.url),
          'thumbnail': new URL('../assets/posters/ko_297x420mm_do_the_right_things_c.jpg?width=250', import.meta.url),
          'pdf': new URL('../assets/posters/ko_297x420mm_do_the_right_things_c.pdf', import.meta.url),
        },
        {
          'preview': new URL('../assets/posters/ko_297x420mm_win_as_a_team_a.jpg', import.meta.url),
          'thumbnail': new URL('../assets/posters/ko_297x420mm_win_as_a_team_a.jpg?width=250', import.meta.url),
          'pdf': new URL('../assets/posters/ko_297x420mm_win_as_a_team_a.pdf', import.meta.url),
        },
        {
          'preview': new URL('../assets/posters/ko_297x420mm_win_as_a_team_b.jpg', import.meta.url),
          'thumbnail': new URL('../assets/posters/ko_297x420mm_win_as_a_team_b.jpg?width=250', import.meta.url),
          'pdf': new URL('../assets/posters/ko_297x420mm_win_as_a_team_b.pdf', import.meta.url),
        },
        {
          'preview': new URL('../assets/posters/ko_297x420mm_win_as_a_team_c.jpg', import.meta.url),
          'thumbnail': new URL('../assets/posters/ko_297x420mm_win_as_a_team_c.jpg?width=250', import.meta.url),
          'pdf': new URL('../assets/posters/ko_297x420mm_win_as_a_team_c.pdf', import.meta.url),
        },
    ],
    'US Tabloid': null,
    '700x1000mm': [
      {
        'preview': new URL('../assets/posters/ko_700x1000mm_show_you_care_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/ko_700x1000mm_show_you_care_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/ko_700x1000mm_show_you_care_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/ko_700x1000mm_show_you_care_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/ko_700x1000mm_show_you_care_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/ko_700x1000mm_show_you_care_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/ko_700x1000mm_show_you_care_c.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/ko_700x1000mm_show_you_care_c.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/ko_700x1000mm_show_you_care_c.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/ko_700x1000mm_do_the_right_things_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/ko_700x1000mm_do_the_right_things_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/ko_700x1000mm_do_the_right_things_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/ko_700x1000mm_do_the_right_things_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/ko_700x1000mm_do_the_right_things_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/ko_700x1000mm_do_the_right_things_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/ko_700x1000mm_do_the_right_things_c.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/ko_700x1000mm_do_the_right_things_c.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/ko_700x1000mm_do_the_right_things_c.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/ko_700x1000mm_win_as_a_team_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/ko_700x1000mm_win_as_a_team_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/ko_700x1000mm_win_as_a_team_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/ko_700x1000mm_win_as_a_team_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/ko_700x1000mm_win_as_a_team_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/ko_700x1000mm_win_as_a_team_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/ko_700x1000mm_win_as_a_team_c.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/ko_700x1000mm_win_as_a_team_c.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/ko_700x1000mm_win_as_a_team_c.pdf', import.meta.url),
      },
    ],
  },
};
